import { ButtonHTMLAttributes, memo } from 'react';
import cls from './Burger.module.scss';
import { classNames, Mods } from '../../lib/classNames/classNames';

export interface BurgerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  open: boolean;
  onClick: () => void;
}

const BurgerUI = (props: BurgerProps) => {
  const { className, open, onClick, ...otherProps } = props;

  const mods: Mods = {
    [cls.opened]: open,
  };

  return (
    <button
      name='burger'
      id='burger'
      type='button'
      aria-label='burger'
      className={classNames(cls.Burger, mods, [className])}
      onClick={onClick}
      {...otherProps}
    >
      <span />
      <span />
      <span />
    </button>
  );
};

export const Burger = memo(BurgerUI);
