import { memo } from 'react';
import { lazyRetry } from '@/shared/lib/lazyRetry/lazyWithRetry';
import { SuspenseWrapper } from '@/shared/ui/SuspenseWrapper/SuspenseWrapper';

export interface HomePageSidebarProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const Content = lazyRetry(() => import('./HomePageSidebar'));

export const HomePageSidebar = memo((props: HomePageSidebarProps) => (
  <SuspenseWrapper>
    <Content {...props} />
  </SuspenseWrapper>
));
