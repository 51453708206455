/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';

export const useDragDrop = () => {
  const [DragDrop, setDragDrop] = useState<any | null>(null);

  useEffect(() => {
    import('react-drag-drop-files').then(module => {
      setDragDrop(() => module.FileUploader);
    });
  }, []);
  return DragDrop;
};
