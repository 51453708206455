import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQuery } from '../selector/getBlogItems';
import { ThunkConfig } from '@/app/providers/StorProvider';
import { Blog } from '@/entities/Blog';

interface BlogParams {
  replace: boolean;
}

export const fetchBlogs = createAsyncThunk<
  Iresponse<Blog[]>,
  BlogParams,
  ThunkConfig<string[]>
>('blogs/fetchBlogsData', async (_, { extra, rejectWithValue, getState }) => {
  try {
    const query = getQuery(getState());
    const response = await extra.api.get<Iresponse<Blog[]>>(`blogs${query}`);
    return response.data;
  } catch (e) {
    return rejectWithValue(['service']);
  }
});
