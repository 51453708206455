import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from '@/app/providers/StorProvider';
import { getQueryParams } from '@/shared/lib/url';

export const getIsLoading = (state: StateSchema) => state?.articles?.isLoading;
export const getError = (state: StateSchema) => state?.articles?.error;
export const getAllArticles = (state: StateSchema) => state?.articles?.articles;
export const getTotalPages = (state: StateSchema) =>
  state?.articles?.totalPages || 1;
export const getPage = (state: StateSchema) => state?.articles?.page || 1;
export const getStep = (state: StateSchema) => state?.articles?.step || 10;
export const getSearch = (state: StateSchema) => state?.articles?.search;

export const getQuery = createSelector(
  getStep,
  getSearch,
  getPage,
  (size, search, page) =>
    getQueryParams({
      limit: size.toString(),
      page: page.toString(),
      search,
    }),
);
